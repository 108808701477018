import React, { lazy } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './styles/basic.scss';

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

// Containers
const Home = lazy(() => import('./views/home'));
const Category = lazy(() => import('./views/category'));
const Tag = lazy(() => import('./views/tag'));
const Topic = lazy(() => import('./views/topic'));
const Search = lazy(() => import('./views/search'));
const Detail = lazy(() => import('./views/detail'));
const Page404 = lazy(() => import('./views/breaks/Page404'));
const Page500 = lazy(() => import('./views/breaks/Page500'));

const App = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path='/category/:id'
            name='Category Page'
            element={<Category />}
          />
          <Route exact path='/tag/:id' name='Tag Page' element={<Tag />} />
          <Route
            exact
            path='/topic/:id'
            name='Topic Page'
            element={<Topic />}
          />
          <Route exact path='/search' name='Search Page' element={<Search />} />
          <Route
            exact
            path='/article/:id'
            name='Detail Page'
            element={<Detail />}
          />
          <Route exact path='/404' name='Page 404' element={<Page404 />} />
          <Route exact path='/500' name='Page 500' element={<Page500 />} />
          <Route path='/' name='Home' element={<Home />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
